import * as React from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { authAtom, isLoginAtom, userInfoAtom } from '../../store/user';
import iconDel from '../../assets/icon-del.svg';
import { usePopup } from '../../components/Popup';
import { CircularProgress } from '@mui/material';
import History from '../../components/History';
import { useGenerateHistory, useUserInfo } from '../../hooks/apiHooks';
import { useNavigate } from 'react-router-dom';

export default function Audio(props) {
  const [auth] = useAtom(authAtom);
  const [fileList, setFileList] = useState([]);
  const [holder, { open, close }] = usePopup();
  const [userInfo, loadingUserInfo] = useUserInfo();
  const { mutate } = useGenerateHistory();
  const nav = useNavigate();
  useEffect(() => {
    if (!loadingUserInfo && !userInfo) {
      nav('/home', { replace: true });
    }
  }, [userInfo, loadingUserInfo]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileList.length === 0) {
      alert('请上传音频或视频');
      return;
    }
    if (!e.target.text.value) {
      alert('请输入生成文本');
      return;
    }
    open({
      content: <CircularProgress color="primary" />,
    });

    const formData = new FormData();
    formData.append('text', e.target.text.value);
    formData.append('username', userInfo?.nickname || '');
    fileList.forEach((file) => {
      formData.append('files', file);
    });
    try {
      await fetch('https://open.shiflow.com/shiflow-gpt-tuna/upload', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: auth,
        },
      });
      await mutate('getGenerateHistory');
      e.target.reset();
      setFileList([]);
    } catch (e) {}
    close();
  };
  const [text, setText] = useState('');
  return (
    <>
      {holder}
      <form onSubmit={handleSubmit} className="flex h-full flex-col items-stretch pb-12 pl-20 pr-14 pt-8 max-md:px-5">
        <header className="flex w-full items-start justify-between gap-5 px-px max-md:max-w-full max-md:flex-wrap">
          <span className="mt-4 flex items-start gap-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff281325e1af4ccf46f5d58d143869126f124eccd9aa55389873020e335b71cd?apiKey=4e303c7d00e7454480826d21ae537b54&"
              className="mt-2.5 aspect-[3.13] w-[50px] max-w-full shrink-0 overflow-hidden object-contain object-center"
              alt="Logo"
            />
            <div className="text-2xl text-white">TUNA</div>
          </span>
          <span className="flex items-stretch gap-3.5">
            <img
              loading="lazy"
              src={
                userInfo?.avatar ||
                'https://cdn.builder.io/api/v1/image/assets/TEMP/745581bb6d424714142ad08d523fdaeebcbc6737ad486e5eadfce6379f16d24c?apiKey=4e303c7d00e7454480826d21ae537b54&'
              }
              className="aspect-square w-[27px] max-w-full shrink-0 overflow-hidden object-contain object-center"
              alt="User Avatar"
            />
            <div className="my-auto grow self-center whitespace-nowrap text-base leading-7 text-white">
              {userInfo?.nickname}
            </div>
          </span>
        </header>
        <div className="flex">
          <div className="mt-28 max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:items-stretch max-md:gap-0">
              <div className="flex w-[74%] max-w-[895px] flex-col  items-stretch max-md:ml-0">
                <div className="self-stretch text-xl text-white max-md:max-w-full">上传真人说话声音素材</div>
                <div className="mt-7 self-stretch text-lg font-light text-white max-md:max-w-full">
                  上传您的声音（最多3个），声音的质量比数量重要，吐纳AI将在后台训练和克隆您的声音。这个过程通常需要等待24-48小时，请稍作等待。每天限制生成10个。
                </div>
                <div className="flex flex-wrap items-center">
                  {fileList.map((file, index) => {
                    return (
                      <div key={index} className="relative ml-[20px] mt-7 self-start first-of-type:ml-[0] max-md:px-5">
                        <label className="block max-w-[320px] items-stretch justify-center overflow-hidden text-ellipsis whitespace-nowrap rounded-[29px] border border-solid border-white  bg-black py-3.5 pl-6 pr-7 text-base text-white ">
                          {file.name}
                        </label>
                        <img
                          onClick={() => {
                            console.info('wtf hie', index);
                            setFileList((list) => {
                              list.splice(index, 1);
                              console.info('wtf list', list);
                              return [...list];
                            });
                          }}
                          src={iconDel}
                          className="absolute right-[-10px] top-[-10px] h-[30px] w-[30px] cursor-pointer"
                        />
                      </div>
                    );
                  })}
                  {fileList.length < 3 && (
                    <div className="ml-[20px] flex first-of-type:ml-[0]">
                      <label
                        className="mt-7 items-stretch justify-center self-start whitespace-nowrap rounded-[29px] border border-solid border-white bg-black py-3.5 pl-6 pr-7 text-base text-white max-md:px-5"
                        aria-label="Upload audio or video"
                        htmlFor="test"
                      >
                        上传音频或视频
                      </label>
                      <input
                        // accept=".mp3,.mp4"
                        type="file"
                        className="absolute hidden"
                        name="file"
                        id="test"
                        onChange={(e) => {
                          const [file] = e.target.files;
                          if (file) {
                            if (fileList.some(({ name }) => name === file.name)) {
                              console.info('wtf hi');
                              e.target.value = '';
                              alert('请勿上传重复文件');
                              return;
                            }
                            setFileList([...fileList, e.target.files[0]]);
                            e.target.value = '';
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="mt-20 self-stretch text-xl text-white max-md:mt-10 max-md:max-w-full">输入生成本文</div>
              </div>
              {/*<div cl assName="ml-5 ml-auto flex w-[26%] flex-col items-stretch max-md:ml-0 max-md:w-full">*/}
              {/*  <History />*/}
              {/*</div>*/}
            </div>
            <textarea
              onChange={(e) => setText(e.target.value)}
              name="text"
              className="mt-8 w-full max-w-[660px] items-start self-start rounded-xl border border-solid border-zinc-600 bg-black pb-44 pl-4 pr-16 pt-5 text-xl font-light text-white max-md:max-w-full max-md:pb-10 max-md:pr-5"
              placeholder="请输入准备让AI朗读的文案，目前算力有限，请输入500个汉字以内的句子。"
            ></textarea>
          </div>
          <History className="ml-auto" />
        </div>
        <button
          disabled={fileList.length > 0 && text.length > 0 ? '' : 'disabled'}
          type="submit"
          className="mb-28 mt-10 items-stretch justify-center self-start whitespace-nowrap rounded-[29px] bg-[#0088B7] px-6 py-2.5 text-base text-white disabled:cursor-not-allowed disabled:bg-sky-300 max-md:mb-10 max-md:px-5"
          aria-label="Start training"
        >
          开始训练
        </button>
      </form>
    </>
  );
}
