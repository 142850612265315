import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

export function usePopup() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [content, setContent] = useState('123');
    const [props, setProps] = useState({});
    const _html = (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    {content}
                </Box>
            </Modal>
        </>
    );
    return [
        <Modal
            open={open}
            onClose={handleClose}
            {...props}
        >
            <Box sx={style}>
                {content}
            </Box>
        </Modal>,
        {
            open: ({ content: _content, ...rest }) => {
                setContent(_content);
                setProps(rest)
                handleOpen();
            },
            close: handleClose
        }
    ];
}
