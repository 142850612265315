import { useRequest } from 'ahooks';
import { useGenerateHistory } from '../hooks/apiHooks';
import { CircularProgress } from '@mui/material';

export default function History({ className }) {
  const b = useGenerateHistory();
  const { data, isLoading, isValidating } = b;
  return (
    <div className={`w-[330px] overflow-hidden ${className}`}>
      <header className="header w-full text-xl text-white">生成历史</header>
      <div className="mt-7 min-h-[1px] w-full overflow-hidden bg-zinc-600" />
      {!data?.body && (isValidating || isLoading) && (
        <div className="mt-[24px] flex h-[24px] justify-center">
          <CircularProgress color="info" size={24} />
        </div>
      )}
      {data?.body?.items?.map((item, index) => {
        const {
          fields: { '平台成品上传（给用户下载）': downloadLink, 用户文案: uploadText },
        } = item;
        return (
          <section key={index} className="mt-6 flex w-full items-stretch justify-between">
            <div className="flex flex-1 items-center overflow-hidden text-base text-white">
              <div className="block w-full overflow-hidden text-ellipsis whitespace-nowrap">{uploadText}</div>
            </div>
            {downloadLink ? (
              <button
                className="flex  h-[30px] w-[70px] flex-shrink-0 items-center justify-center self-stretch whitespace-nowrap rounded-lg border border-solid border-white bg-zinc-700 text-base text-white"
                aria-label="下载"
                aria-role="button"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(downloadLink);
                }}
              >
                下载
              </button>
            ) : (
              <div className="ml-auto flex h-[30px] w-[70px] flex-shrink-0 items-center justify-center whitespace-nowrap rounded-lg text-base text-white">
                生成中
              </div>
            )}
          </section>
        );
      })}
    </div>
  );
}
