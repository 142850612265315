import React, { useRef, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Modal, Portal, Snackbar, TextField, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { authAtom, loginStatusAtom, userInfoAtom } from '../store/user';
import Toast from './Toast';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  // p: 4,
};

const login = async (data) => {
  const res = await fetch('https://open.shiflow.com/shiflow/v1/login', {
    method: 'POST',
    body: data,
  });
  return res.json();
};

const getSelf = async (auth) => {
  const res = await fetch('https://open.shiflow.com/shiflow/v1/self', {
    headers: {
      Authorization: auth,
    },
  });
  return res.json();
};

function LoginForm({ onSuccess, onError, onClose }) {
  const [, setAuth] = useAtom(loginStatusAtom);
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!e.target.username.value) {
      alert('请输入用户名');
      return;
    }
    if (!e.target.password.value) {
      alert('请输入密码');
      return;
    }
    setLoading(true);
    try {
      const { body, code, msg = '' } = await login(new FormData(e.target));
      if (code !== 0) {
        throw new Error(msg);
      }
      setAuth(body.access_token);
      onSuccess?.();
      setLoading(false);
    } catch (e) {
      console.info('wtf error');
      onError?.(e.message);
      setLoading(false);
      console.error(e);
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      style={{ backgroundColor: '#3D404A' }}
      className="relative flex max-w-[396px] flex-col items-center overflow-hidden fill-zinc-700 px-14 py-8"
    >
      <header className="relative whitespace-nowrap text-lg text-white"> TUNA登录</header>
      <div
        className="relative mt-8 items-start justify-center self-stretch whitespace-nowrap rounded-md border border-solid border-white bg-zinc-700 p-3 text-base text-stone-300"
        role="textbox"
        aria-label="Email Input"
      >
        <label className="sr-only">Email</label>
        <input
          name="username"
          className="w-full bg-transparent focus-visible:border-none focus-visible:outline-none"
          placeholder="请输入您的邮箱"
          type="text"
          aria-label="Email Input"
        />
      </div>
      <div
        className="relative mt-4 items-start justify-center self-stretch whitespace-nowrap rounded-md border border-solid border-white bg-zinc-700 p-3 text-base text-stone-300"
        role="textbox"
        aria-label="Password Input"
      >
        <label className="sr-only">Password</label>
        <input
          name="password"
          className="w-full bg-transparent focus-visible:border-none focus-visible:outline-none"
          placeholder="请输入密码"
          type="password"
          aria-label="Password Input"
        />
      </div>
      <div className="mt-[17px] flex w-full items-stretch justify-between">
        <button
          type="submit"
          disabled={loading}
          className="flex h-[45px] w-[130px] items-center justify-center rounded-[38px] bg-cyan-600 text-center text-base text-white"
          aria-label="Login"
        >
          {loading ? <CircularProgress color="inherit" size={20} /> : '登录'}
        </button>
        <button
          type="button"
          className="h-[45px] w-[130px] items-center justify-center rounded-[49px] border border-solid border-white bg-zinc-700 text-center text-base text-white"
          aria-label="Cancel"
          onClick={onClose}
        >
          取消
        </button>
      </div>
      <div className="relative mt-[31px] whitespace-nowrap text-center text-base font-light text-white underline">
        还没有TUNA账号？请咨询工作人员
      </div>
    </form>
  );
}

export function useLoginModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const callbackRef = useRef();

  const [toastVisible, showToast] = useState(false);
  const [severity, setSeverity] = useState();
  const [toastText, setToastText] = useState('');
  const typeList = ['success', 'info', 'warning', 'error'];
  const toast = {};
  const hideSnackBar = () => {
    showToast(false);
  };
  typeList.forEach((type) => {
    toast[type] = (text) => {
      setSeverity(type);
      setToastText(text);
      showToast(true);
    };
  });

  const __html = (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={toastVisible}
            onClose={hideSnackBar}
            autoHideDuration={1500}
          >
            <Alert severity={severity} variant="filled">
              {toastText}
            </Alert>
          </Snackbar>
          <LoginForm
            onSuccess={() => {
              toast.success('登录成功');
              callbackRef.current?.();
              handleClose();
            }}
            onClose={handleClose}
            onError={() => {
              toast.error('用户名或密码错误');
            }}
          />
        </Box>
      </Modal>
    </>
  );
  return [
    __html,
    {
      open: (_callback) => {
        callbackRef.current = _callback;
        handleOpen();
      },
      close: handleClose,
    },
  ];
}
