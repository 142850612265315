import * as React from 'react';
import { useLoginModal } from '../../components/LoginForm';
import { Link, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { isLoginAtom } from '../../store/user';
import { useUserInfo } from '../../hooks/apiHooks';
import { useEffect, useLayoutEffect, useRef } from 'react';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import Player from 'xgplayer';
import 'xgplayer/dist/index.min.css';
export default function VoiceCloningComponent(props) {
  const [holder, { open, close }] = useLoginModal();
  const [isLogin] = useAtom(isLoginAtom);
  const navigate = useNavigate();
  const [userInfo, loading] = useUserInfo();
  const videoRef = useRef(null);
  useLayoutEffect(() => {
    let player = new Player({
      controls: false,
      id: 'mse',
      url: '/video.mp4',
      width: '590px',
    });
  }, []);
  return (
    <div className="flex h-full flex-col items-center bg-black px-[80px]">
      {holder}
      <header className="sticky left-0 top-0 flex w-full items-center justify-between gap-5 bg-black py-[40px]">
        <span className="flex items-center gap-5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff281325e1af4ccf46f5d58d143869126f124eccd9aa55389873020e335b71cd?apiKey=4e303c7d00e7454480826d21ae537b54&"
            className="aspect-[3.13] w-[50px] max-w-full shrink-0 overflow-hidden object-contain object-center"
            alt="Logo"
          />
          <div className="text-2xl text-white">TUNA</div>
        </span>
        <span className="flex items-stretch justify-between gap-5 ">
          <div className="grow whitespace-nowrap text-2xl text-white">AI声音克隆</div>
          <div className="text-2xl text-white">AI文生视频</div>
          <a href="#" className="self-start text-2xl text-white">
            联系我们
          </a>
          <div
            onClick={() => {
              if (!userInfo) {
                open();
              }
            }}
            className="self-start whitespace-nowrap text-2xl text-white"
          >
            {userInfo?.nickname || '登录'}
          </div>
        </span>
      </header>
      <div className="mt-[80px] flex h-[790px] w-full">
        <div className="flex items-center">
          <div className="flex flex-col items-stretch">
            <div className="my-auto flex w-[650px] flex-col items-start">
              <h1 className="self-stretch text-[90px] font-bold text-white">吐纳AI·声音克隆</h1>
              <p className="mt-[20px] self-stretch text-[25px] font-light text-white">
                上传真人说话的声音（音频和视频文件），并输入文案。吐纳AI可以克隆真人的音色，去朗读你输入的文案并生成音频文件
              </p>
              <Link
                to="/audio"
                onClick={(e) => {
                  if (userInfo) {
                    return;
                  }
                  e.stopPropagation();
                  e.preventDefault();
                  open(() => {
                    navigate('/audio');
                  });
                }}
              >
                <button className="mt-11 w-[225px] max-w-full items-start justify-center whitespace-nowrap rounded-[199px] bg-cyan-600 px-16 py-4 text-2xl text-white">
                  立即使用
                </button>
              </Link>
            </div>
          </div>
          <div className="ml-[110px] flex flex-col items-stretch">
            <img
              loading="lazy"
              srcSet="/animate.gif"
              width={590}
              className="my-auto object-contain object-center "
              alt="Image"
            />
          </div>
        </div>
      </div>
      <div className="mt-[80px] flex h-[790px] w-full">
        <div className="flex items-center">
          <div className="flex flex-col items-stretch">
            <div className="my-auto flex w-[650px] flex-col items-start">
              <h1 className="self-stretch text-[90px] font-bold text-white">吐纳AI·文生视频</h1>
              <p className="mt-[20px] self-stretch text-[25px] font-light text-white">
                输入文字描述，AI帮你生成视频，内测期试用，请联系工作人员
              </p>
              <Link
                to="/audio"
                onClick={(e) => {
                  if (userInfo) {
                    return;
                  }
                  e.stopPropagation();
                  e.preventDefault();
                  open(() => {
                    navigate('/audio');
                  });
                }}
              >
                <button className="mt-11 w-[225px] max-w-full items-start justify-center whitespace-nowrap rounded-[199px] border-[1px] border-solid border-cyan-600 px-16 py-4 text-2xl text-cyan-600">
                  联系我们
                </button>
              </Link>
            </div>
          </div>

          <div className="relative ml-[110px] flex flex-col items-stretch">
            <div
              style={{ border: 'solid 1px white' }}
              className="height-[37px] absolute bottom-[20px] left-1/2 z-[1] flex w-[88%] -translate-x-1/2 items-center rounded-[31px] bg-[rgba(235,235,235,0.5)] pl-[20px] text-[13px] text-white"
            >
              华丽星辰，层叠山峰，一个人站在中间
            </div>
            <div id="mse"></div>
          </div>
        </div>
      </div>
      <div className="text-base text-zinc-500 pb-[50px]">
        <a href="https://beian.miit.gov.cn/" className="text-zinc-500 underline" target="_blank">
          京ICP备2023011855号
        </a>
        {' | '}
        <a
          href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802042627"
          className="text-zinc-500 underline"
          target="_blank"
        >
          京公网安备 11010802042627号
        </a>
        {'  | © 2024 gptuna.com'}
      </div>
    </div>
  );
}
