import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { isLoginAtom } from '../store/user';
import useSWR from 'swr'
import { useUserInfo } from '../hooks/apiHooks'

const AuthRoute = ({ children, auth }) => {
    const [isLogin] = useAtom(isLoginAtom);
    if (isLogin) {
        return children;
    }
    return <Navigate to="/"/>;
};
export default AuthRoute;
