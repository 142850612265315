import { atom } from 'jotai';
import Cookie from 'js-cookie';

export const authAtom = atom(Cookie.get('shiflow_auth'));
export const isLoginAtom = atom(get => !!get(authAtom));
export const loginStatusAtom = atom(get => get(authAtom), (_, set, v) => {
    set(authAtom, `JWT ${v}`);
    Cookie.set('shiflow_auth', `JWT ${v}`);
});

export const userInfoAtom = atom(undefined);

