import { useAtom } from 'jotai';
import { authAtom, userInfoAtom } from '../store/user';
import { useRequest } from 'ahooks';
import useSWR from 'swr';

const fetchHistory = async (auth) => {
  const res = await fetch('https://open.shiflow.com/shiflow-gpt-tuna/history', {
    headers: {
      Authorization: auth,
    },
  });
  return res.json();
};

export function useGenerateHistory() {
  const [auth] = useAtom(authAtom);
  return useSWR(auth ? 'getGenerateHistory' : null, () => fetchHistory(auth));
}

export const getSelf = async (auth) => {
  const res = await fetch('https://open.shiflow.com/shiflow/v1/self', {
    headers: {
      Authorization: auth,
    },
  });
  return res.json();
};

export function useUserInfo() {
  const [auth] = useAtom(authAtom);
  const { data, loading } = useRequest(auth ? () => getSelf(auth) : null, { cacheKey: 'getUserInfo' });
  return [data?.body, loading];
}
