import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React, { lazy, useEffect, useLayoutEffect } from 'react';
import Home from './pages/Home';
import Audio from './pages/Audio';
import AuthRoute from './router/auth';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/audio',
    element: (
      <AuthRoute>
        <Audio />
      </AuthRoute>
    ),
  },
]);
const resize = () => {
  // 系统整体缩放
  let cliWidth = document.documentElement.clientWidth || document.body.clientWidth;
  let cliHeight = document.documentElement.clientHeight || document.body.clientHeight;
  let contW = 1512;
  let contH = 982;
  let w = cliWidth / contW;
  let h = cliHeight / contH;
  let appDom = document.querySelector('#app');
  let size = cliWidth / cliHeight;
  appDom.style.zoom = Math.min(0.85, w);
  appDom.style.transformOrigin = 'top left';
  appDom.style.width = Math.max(cliWidth / w, w) + 'px';
};

function App() {
  useLayoutEffect(() => {
    resize();
    window.addEventListener('resize', resize);
  }, []);
  return (
    <div id="app" className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
